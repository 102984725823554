import { createSSRApp, h } from 'vue';

//new
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import { Head,Link } from '@inertiajs/vue3';


import '@themesberg/flowbite';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
//https://fontawesome.com/icons/chevron-up?s=solid&f=classic
import moment from 'moment'
import axios from "axios";
//import Toaster from "@meforma/vue-toaster";


library.add(fas)

createInertiaApp({
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        return createSSRApp({ render: () => h(App, props) })
            .component('InertiaHead', Head)
            .component('InertiaLink', Link)
            .component('font-awesome-icon', FontAwesomeIcon)
            .mixin({
                methods: {
                    asset(path) {
                        if(import.meta.env.VITE_FRONTEND_URL!==undefined){
                            return import.meta.env.VITE_FRONTEND_URL +'storage/'+ path;
                        }
                        if(website_domain && website_domain!==undefined){
                            return 'https://'+website_domain +'/storage/'+ path;
                        }
                        console.log('env.VITE_FRONTEND_URL is missing');
                    },
                    env() {
                        return import.meta.env;
                    },
                    money_format(money) {
                       return new Intl.NumberFormat('nl-NL', {style: 'currency', currency: 'EUR',minimumFractionDigits:0 }).format(money)+',-';
                    },
                    formatDate(value) {
                        return moment(String(value)).format('DD-MM-YYYY')
                    },
                    currency(number) {
                        return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(number);
                    },
                    strippedContent(content ) {
                        if (content !==null && content.length > 0) {
                            let regex = /(<([^>]+)>)/ig;
                            return content.toString().replace(regex, "").substring(0, 100);
                        }
                    },
                    setCookie(name,value,days) {
                        var expires = "";
                        if (days) {
                            var date = new Date();
                            date.setTime(date.getTime() + (days*24*60*60*1000));
                            expires = "; expires=" + date.toUTCString();
                        }
                        if (typeof document !== "undefined") {
                            document.cookie = name + "=" + (value || "") + expires + "; path=/";
                        }
                    }, getCookie(name) {
                        var nameEQ = name + "=";
                        if (typeof document !== "undefined") {
                            var ca = document.cookie.split(';');
                        }
                        for(var i=0;i < ca.length;i++) {
                            var c = ca[i];
                            while (c.charAt(0)==' ') c = c.substring(1,c.length);
                            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
                        }
                        return null;
                    },
                    addFavourite (variant_id){

                        let cookie = this.getCookie('favourite');
                        //  console.log(cookie, variant.id);
                        axios.post(route('favourite.store'), {
                            cookie : cookie,
                            variant_id: variant_id,
                        }).then((response) => {
                            this.is_favourite = response.data.is_favourite;//
                            this.website.favorite_variants = response.data.favourites;
                            this.website.favorite_url =  response.data.favourite_url;
                            this.setCookie('favourite',response.data.cookie,1000);
                        })
                    },
                    isFavourite(variant_id){
                        if(this.website.favorite_variants!==undefined){
                            let found = this.website.favorite_variants.find(element => element.id == variant_id);
                            if(found!==undefined){
                                return true;
                            }
                            return false;
                        }
                    },
                }
            })
            .use(plugin)
            .use(ZiggyVue, Ziggy)
         //   .use(Toaster)
            .mount(el);
    },
    progress: {
        color: '#4B5563',
    },
});

